import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Container, Img, MainContainer, Row } from 'ui/layout';
import { H4, InteractLink, Text } from 'ui/typography';
import { BREAKPOINTS, COLORS } from 'utils';
import rutube from 'assets/common/rutube.svg';
import odnokl from 'assets/common/odnokl.svg';
import vk from 'assets/common/vk.svg';
import arrow_right from 'assets/common/arrow_right.svg';

const currentYear = new Date().getFullYear();

export const Footer = (props) => {
  const [value, setValue] = useState('');

  const catchEnter = (e) => {
    if (e.key === 'Enter') {
      sendEmail();
    }
  };
  const sendEmail = () => {
    setValue('');
  };

   const onButtonClick = (file) => {
     fetch(file).then((response) => {
       response.blob().then((blob) => {
         const fileURL = window.URL.createObjectURL(blob);
         let alink = document.createElement('a');
         alink.href = fileURL;
         alink.download = file;
         alink.click();
       });
     });
    }

  return (
    <FooterContainer {...props}>
      <StyledMainContainer>
        <Row gap={30} xlGap={15} lgGap={25}>
          <Col width={'33.333%'} lgWidth={'37.3%'} smdWidth={'100%'}>
            <MenuCol>
              <FlexCol smdHidden height={'100%'} lgHeight={'159px'}>
                <StyledLink to={'/'}>Главная</StyledLink>
                <StyledLink to={'/news'}>Новости</StyledLink>
                <StyledLink to={'/projects'}>Проекты</StyledLink>
                <StyledLink to={'/partners'}>Поставщикам</StyledLink>
                <StyledLink to={'/contacts'}>Контакты</StyledLink>
              </FlexCol>
            </MenuCol>
          </Col>
          <Col width={'66.667%'} lgWidth={'62.7%'} smdWidth={'100%'}>
            <MainRow
              gap={30}
              xlGap={10}
              lgGap={'0'}
              cols={2}
              lgCols={1}
              lgAlignStart={'start'}
            >
              <Col
                paddingTop={60}
                paddingBottom={60}
                lgPaddingBottom={48}
                smdPaddingTop={37}
              >
                <Container marginBottom={24}>
                  <UpdLink href='tel:84958990564' color={COLORS.white}>
                    +7 495 899 05 64
                  </UpdLink>
                </Container>
                <Container marginBottom={48}>
                  <UpdLink href='mailto:info@allgk.ru' color={COLORS.white}>
                    info@allgk.ru
                  </UpdLink>
                </Container>

                <Row
                  smdJustifyContent={'center'}
                  gap={6}
                  smdGap={5.5}
                  marginTop={54}
                  lgMarginTop={44}
                  smdMarginTop={40}
                >
                  <Col auto>
                    <a href={'/'} target={'_blank'}>
                      <Img src={vk} width={29} alt={''} />
                    </a>
                  </Col>
                  <Col auto>
                    <a href={'/'} target={'_blank'}>
                      <Img src={odnokl} width={29} alt={''} />
                    </a>
                  </Col>
                  <Col auto>
                    <a href={'/'} target={'_blank'}>
                      <Img src={rutube} width={29} alt={''} />
                    </a>
                  </Col>
                </Row>
              </Col>
              <FlexCol
                lgPaddingTop={1}
                lgPaddingBottom={1}
                paddingTop={60}
                paddingBottom={55}
                lgJustifyContent={'flex-start'}
                smdMarginTop={11}
              >
                <FeedbackContainer>
                  <Typography>Связаться с нами</Typography>
                  <Container relative marginTop={8}>
                    <Input
                      placeholder={'E-mail'}
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      onKeyDown={catchEnter}
                    />
                    <EnterArrow onClick={sendEmail} />
                  </Container>
                </FeedbackContainer>

                <Container marginY={10}>
                  <StyledDownloadLink
                    onClick={() =>
                      onButtonClick('Сводная_ведомость_результатов_СОУТ.pdf')
                    }
                  >
                    Сводная ведомость результатов СОУТ
                  </StyledDownloadLink>
                </Container>

                <Container marginY={10}>
                  <StyledDownloadLink
                    onClick={() => onButtonClick('Сводная_ведомость.pdf')}
                  >
                    Сводная ведомость
                  </StyledDownloadLink>
                </Container>

                <Container marginY={10}>
                  <StyledDownloadLink
                    onClick={() =>
                      onButtonClick(
                        'Перечень_рекомендуемых_мероприятий_по_улучшению_условий_труда.pdf'
                      )
                    }
                  >
                    Перечень рекомендуемых мероприятий по улучшению условий
                    труда 1
                  </StyledDownloadLink>
                </Container>

                <Container marginY={10}>
                  <StyledDownloadLink
                    onClick={() =>
                      onButtonClick(
                        'Перечень_рекомендуемых_мероприятий_по_улучшению_условий_охраны.pdf'
                      )
                    }
                  >
                    Перечень рекомендуемых мероприятий по улучшению условий
                    охраны 2
                  </StyledDownloadLink>
                </Container>

                <Container marginY={10}>
                  <StyledDownloadLink
                    onClick={() =>
                      onButtonClick(
                        'Сводная_ведомость_результатов_проведения_СОУТ_2024_ОП_Орлово_1.pdf'
                      )
                    }
                  >
                    Сводная ведомость результатов проведения СОУТ 2024 ОП Орлово
                  </StyledDownloadLink>
                </Container>

                <Container marginY={10}>
                  <StyledDownloadLink
                    onClick={() =>
                      onButtonClick(
                        'Перечень_мероприятий_по_улучшению_условий_труда_ОП_Орлово_01.pdf'
                      )
                    }
                  >
                    Перечень мероприятий по улучшению условий труда ОП Орлово 1
                  </StyledDownloadLink>
                </Container>

                <Container marginY={10}>
                  <StyledDownloadLink
                    onClick={() =>
                      onButtonClick(
                        'Перечень_мероприятий_по_улучшению_условий_труда_ОП_Орлово_02.pdf'
                      )
                    }
                  >
                    Перечень мероприятий по улучшению условий труда ОП Орлово 2
                  </StyledDownloadLink>
                </Container>

                <RightsText>
                  © Группа компаний «Альянс». {currentYear}&nbsp;г.
                </RightsText>
              </FlexCol>
            </MainRow>
          </Col>
        </Row>
      </StyledMainContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled(Container)`
  background: #23222f;
  color: ${COLORS.white};
  @media (max-width: ${BREAKPOINTS.lg}px) {
    /* height: 455px; */
    ${H4} {
      font-size: 25px;
      line-height: 32px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    ${H4} {
      font-size: 20px;
      line-height: 26px;
    }
  }
`;
const StyledMainContainer = styled(MainContainer)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: unset;
    padding: 0 50px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding: 0;
  }
`;
const MobileContainer = styled(Container)``;
const MenuCol = styled.div`
  margin-left: -2px;
  background: #34323d;
  padding: 60px 43px 60px 43px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  line-height: 27px;
  min-height: 279px;
  height: 100%;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    padding: 60px 20px 60px 20px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 60px 10px 60px 30px;
    max-width: 260px;
    min-height: 455px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    display: block;
    padding: 50px 0 0;
    background: none;
    max-width: unset;
    min-height: unset;
    ${MobileContainer} {
      max-width: 329px;
      margin: 0 auto;
    }
  }
`;
const StyledLink = styled(Link)`
  color: ${COLORS.white};
  text-decoration: none;
  display: block;
  margin-top: 4px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    line-height: 20px;
    margin-top: 11px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    white-space: nowrap;
    font-size: 12px;
    line-height: 20px;
    margin-top: 5px;
    max-width: 80px;
  }
`;
const RightsText = styled.div`
  font-weight: 400;
  text-align: end;
  color: ${COLORS.gray};
  @media (max-width: ${BREAKPOINTS.lg}px) {
    text-align: start;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-bottom: 40px;
  }
`;

const MainRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: 360px;
    align-content: ${(p) => (p.lgAlignStart ? p.lgAlignStart : 'inherit')};
  }

  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 369px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    min-height: unset;
  }
`;
const SubtitleBig = styled.p`
  font-size: 25px;
  line-height: 30px;
  font-weight: 600;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 18px;
    line-height: 31px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
const Subtitle = styled(Text)`
  font-size: 16px;
  line-height: 21px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 16px;
    max-width: 228px;
  }
`;
const FeedbackContainer = styled(Container)`
  margin-right: -11px;
  /* padding-left: 11px; */
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 0 0 60px 0;
    padding: 0;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    display: none;
    margin-bottom: 19px;
  }
`;
const EnterArrow = styled.div`
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  width: 31px;
  height: 10px;
  background: url('${arrow_right}') center/contain no-repeat;
  cursor: pointer;
`;
const Input = styled.input`
  width: 100%;
  background: transparent;
  padding: 15px 56px 4px 0;
  border: none;
  border-bottom: 1px solid #ffffff;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 1px;
  color: #ffffff;
  outline: none;

  ::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #ffffff;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 40px;
    padding: 14px 56px 13px 0;
  }
`;

const Typography = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
`;

const UpdLink = styled(InteractLink)`
  font-size: 16px;
  border-bottom: 0;
  &:hover {
    opacity: 0.6;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    text-align: center;
  }
`;

const FlexCol = styled(Container)`
  height: ${(p) => (p.height ? p.height : 'auto')};
  display: flex;
  flex-direction: column;
  /* justify-content: space-between */
  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: ${(p) => (p.lgHeight ? p.lgHeight : 'auto')};
    justify-content: ${(p) =>
      p.lgJustifyContent ? p.lgJustifyContent : 'inherit'};
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    align-items: center;
  }
`;

const StyledDownloadLink = styled.p`
  color: ${COLORS.white};
  cursor: pointer;
  text-decoration: underline;
  :hover {
    opacity: .6;
  }
`;

